import React, { useState } from "react"
import classnames from "classnames"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/angebot/sehhilfen/header.png"
import BildOben from "../../images/angebot/sehhilfen/oben.jpg"
import BildUnten from "../../images/angebot/sehhilfen/unten.png"
import SubNavigation from "../../components/SubNavAngebot"
import Termin from "../../components/Termin"

const Sehhilfen: React.FC = () => {
  const siteTitle = "Sehhilfen - Angebot"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)
  const greenVer = true

  return (
    <Layout title={siteTitle} greenVer={greenVer} background="#00aa9b">
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Optische Sehhilfen"
      />
      <section className="wrapper greyColor content">
        <h1>Optische Sehhilfen</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageRow">
                <div>
                  <img src={BildOben} />
                  <p className="imageCaption">Vergrösserungsgläser</p>
                </div>
              </div>
              <div className="imageRow">
                <div>
                  <img src={BildUnten} />
                  <p className="imageCaption">Ferngläser</p>
                </div>
              </div>
            </div>
          </div>
          <div className="contentColumn">
            <h2>Für vielerlei Anwendungen</h2>
            <p>
              Optische Sehhilfen erleichtern in vielen Bereichen den Alltag.
              SCHWARZ Optik berät Sie gerne bei Ihrer Wahl von optischen
              Sehhilfen wie Lupen, Speziallupen Bildschirmlesegeräten und
              Feldstecher.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Sehhilfen
